import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Typography,
  TextField,
  Avatar,
} from "@material-ui/core";
import api from "../../services/api";
import toastError from "../../errors/toastError";
const SENDING_STATUS = {
  IDLE: "", // Estado inicial ou "inativo"
  SENDING: "Enviando Produto...",
  SENT: "Produto Enviado!",
};


const ProductListModal = ({ open, onClose, ticketId, modalSize = "sm" }) => { // Adicionada a prop modalSize com valor padrão "sm"
  const [sendingStatus, setSendingStatus] = useState(SENDING_STATUS.IDLE);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
   const itemsPerPage = 10;

   useEffect(() => {
    if (open) {
      fetchProducts();
    }
  }, [open]);

  const fetchProducts = async () => {
    setLoading(true);
    try {
      const { data } = await api.get("/produtos", {
        params: { limit: 50 },
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });

      const productsWithImages = await Promise.all(
        data.produtos.map(async (product) => {
          try {
            const response = await api.get(`/produtos/${product.id}/images`, {
              headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            const images = response.data;
            return {
              ...product,
              images: images.map((img) => img.imageUrl), // Salva todas as URLs de imagens
            };
          } catch {
            return {
              ...product,
              images: ["https://via.placeholder.com/50"], // Placeholder caso falhe
            };
          }
        })
      );
      

      setProducts(productsWithImages);
      setFilteredProducts(productsWithImages);
    } catch (err) {
      toastError("Erro ao carregar produtos.");
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = products.filter((product) =>
      product.name.toLowerCase().includes(query)
    );
    setFilteredProducts(filtered);
        setCurrentPage(1); // Resetar para a primeira página após a busca
  };

  // Função para limpar a busca diretamente no código
  const clearSearch = () => {
    setSearchQuery(""); // Limpa o texto do campo de busca
    setFilteredProducts(products); // Restaura a lista original de produtos
        setCurrentPage(1); // Resetar para a primeira página após limpar a busca
  };

  // Você pode chamar a função clearSearch onde for necessário, como aqui:
  useEffect(() => {
    // Exemplo: limpar a busca ao abrir o modal
    if (open) {
      clearSearch();
    }
  }, [open]);

  const handleSendProduct = async (product, priceType) => {
    try {
      let body = `🛍️ *Produto:* ${product.name}\n\n📝 *Descrição:* ${product.message || ""}\n\n`;

      if (priceType === "avista" && product.price) {
        body += `💰 *Preço à Vista:* R$${product.price}`;
      } else if (priceType === "parcelado" && product.price2) {
        body += `💰 *12x de:* R$${product.price2}`;
      } else if (priceType === "all") {
        if (product.price) {
          body += `💰 *Preço à Vista:* R$${product.price}\n`;
        }
        if (product.price2) {
          body += `💰 *12x de:* R$${product.price2}`;
        }
      }

      const images = product.images || [];
      setSendingStatus(SENDING_STATUS.SENDING); // Atualiza o status para "enviando"

      for (let i = 0; i < images.length; i++) {
        const imageUrl = images[i];
        const isLastImage = i === images.length - 1;

        const formData = new FormData();

        if (isLastImage) {
          formData.append("body", body.trim());
        } else {
          formData.append("body", ""); // Envia sem texto nas imagens intermediárias
        }

        formData.append("fromMe", true);

        const response = await fetch(imageUrl);
        const blob = await response.blob();
        const file = new File([blob], `${product.name}-${i + 1}.jpg`, {
          type: blob.type,
        });
        formData.append("medias", file);

        await api.post(`/messages/${ticketId}`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
      }

      setSendingStatus(SENDING_STATUS.SENT); // Atualiza o status para "enviado"

      setTimeout(() => {
        setSendingStatus(SENDING_STATUS.IDLE); // Limpa o estado após 2 segundos
      }, 2000);
    } catch (err) {
      setSendingStatus(SENDING_STATUS.IDLE); // Certifica-se de limpar o estado em caso de erro
      toastError("Erro ao enviar produto.");
    }
  };  

  return (
    <Dialog open={open} onClose={onClose} maxWidth={modalSize}  fullWidth>
      <DialogTitle>Lista de Produtos</DialogTitle>
      <DialogContent>
        <TextField
          label="Pesquisar Produto"
          variant="outlined"
          fullWidth
          value={searchQuery}
          onChange={handleSearchChange}
          style={{ marginBottom: "16px" }}
        />
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
        ) : filteredProducts.length === 0 ? (
          <Typography variant="body1">Nenhum produto encontrado.</Typography>
        ) : (
          <List>
  {filteredProducts
    .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
    .map((product) => {
      const isOutOfStock = !product.quantidade || product.quantidade === 0;
      const isSendDisabled = !product.price && !product.price2;
      const truncatedDescription = product.message
        ? product.message.length > 50
          ? `${product.message.slice(0, 50)}...`
          : product.message
        : "Sem descrição";

      return (
        <ListItem
          key={product.id}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "16px",
            opacity: isOutOfStock ? 0.5 : 1, // Reduz a opacidade se estiver fora de estoque
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              marginBottom: "8px",
            }}
          >
            <Avatar
              src={product.images?.[0] || "https://via.placeholder.com/50"}
              alt={product.name}
              style={{ marginRight: "16px" }}
            />

            <div>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: "bold",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  maxWidth: "calc(100% - 80px)",
                }}
              >
                {product.name.length > 30
                  ? `${product.name.slice(0, 30)}...`
                  : product.name}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {product.images?.length > 1
                  ? `${product.images.length} imagens disponíveis`
                  : "1 imagem disponível"}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {truncatedDescription}
              </Typography>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              flexWrap: "wrap",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ flex: "1 1 auto", marginBottom: "8px" }}
              >
                Preço à Vista: R$ {product.price || " "} | 12x de: R$ {product.price2 || " "}
              </Typography>
              {isOutOfStock && (
  <Typography
    variant="body2"
    style={{
      backgroundColor: "red", // Fundo vermelho
      color: "white",         // Texto branco
      fontWeight: "bold",
      padding: "4px 8px",     // Espaçamento interno
      borderRadius: "4px",    // Bordas arredondadas
      display: "inline-block", // Garante que o fundo siga o texto
      fontSize: "10px",        // Tamanho da fonte (altere conforme necessário)
      position: "relative",    // Permite posicionamento relativo
      top: "-5px",             // Ajuste para subir (-) ou descer (+)
      left: "10px",            // Ajuste para mover à esquerda (-) ou à direita (+)
    }}
  >
    Fora de estoque
  </Typography>
)}

            </div>

            <div
              style={{
                display: "flex",
                gap: "8px",
                flexWrap: "wrap",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleSendProduct(product, "avista")}
                style={{ padding: "6px 8px", fontSize: "12px" }}
              >
                Enviar à Vista
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleSendProduct(product, "parcelado")}
                style={{ padding: "6px 8px", fontSize: "12px" }}
              >
                Enviar Parcelado
              </Button>
              <Button
                variant="contained"
                color="default"
                onClick={() => handleSendProduct(product, "all")}
                style={{ padding: "6px 8px", fontSize: "12px" }}
              >
                Enviar
              </Button>
            </div>
          </div>
        </ListItem>
      );
    })}
</List>


        )}
      </DialogContent>
      <DialogActions>
  <Button
    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
    color="primary"
    disabled={currentPage === 1}
  >
    Página Anterior
  </Button>
  <Typography variant="body2" style={{ margin: "0 8px" }}>
    Página {currentPage}
  </Typography>
  <Button
    onClick={() => setCurrentPage((prev) => Math.min(prev + 1, Math.ceil(filteredProducts.length / itemsPerPage)))}
    color="primary"
    disabled={currentPage === Math.ceil(filteredProducts.length / itemsPerPage)}
  >
    Próxima Página
  </Button>
  <Button onClick={onClose} color="primary">
    Fechar
  </Button>
</DialogActions>


{/* Popup para status de envio */}
<Dialog open={!!sendingStatus}>
  <DialogContent style={{ textAlign: "center", padding: "20px" }}>
    {sendingStatus === "Enviando Produto..." ? (
      <>
        <CircularProgress />
        <Typography style={{ marginTop: "16px" }}>{sendingStatus}</Typography>
      </>
    ) : (
      <Typography style={{ fontWeight: "bold" }}>{sendingStatus}</Typography>
    )}
  </DialogContent>
</Dialog>
<Dialog open={!!sendingStatus}>
        <DialogContent style={{ textAlign: "center", padding: "20px" }}>
          {sendingStatus === SENDING_STATUS.SENDING ? (
            <>
              <CircularProgress />
              <Typography style={{ marginTop: "16px" }}>{sendingStatus}</Typography>
            </>
          ) : (
            <Typography style={{ fontWeight: "bold" }}>{sendingStatus}</Typography>
          )}
        </DialogContent>
      </Dialog>
 </Dialog>
    
  );
};

export default ProductListModal;
